import { CMSSection } from '@/components/CMS/CMSSection'
import { type CMSPageDataWithSpecificLayout } from '@/components/CMS/types'
import { getCMSWrapperProps } from '@/components/CMS/utils/getCMSWrapperProps'

export const GlobalHomePageLayout = ({
  layoutData: { id, content_type_id, data },
}: {
  layoutData: CMSPageDataWithSpecificLayout<'global_home_page_layout'>['content']
}) => {
  const sectionAandB = (data?.body_section_a ?? []).concat(
    data?.body_section_b ?? []
  )
  return (
    <div {...getCMSWrapperProps({ content_type_id, id })}>
      {sectionAandB && <CMSSection section={sectionAandB} />}
    </div>
  )
}
