import { type CouponAPI } from '@/services/Coupons/types'
import { normalizeCoupon } from '@/utils/normalizeCoupon'
import {
  emailValidation,
  studentEmailFormValidation,
} from '@/utils/validations'
import {
  type Content,
  type Section,
  type CMSPageData,
} from '@/components/CMS/types'
import { criteoApi, criteoLoadBeaconForProducts } from '@/analytics/criteo'
import { isProduction } from '@/utils/environment'
import { logError } from '@/utils/logger'
import { cmsSectionComponents } from '@/components/CMS/CMSSection'
import { layoutComponents } from '@/components/CMS/components/Layouts'
import { isOnServer } from '@shared/constants/util'
import { routes } from '@shared/constants/routes'

export const singleEntryFormValidations = {
  EMAIL_VALIDATION: emailValidation,
  STUDENT_EMAIL_VALIDATION: studentEmailFormValidation,
  DEFAULT: emailValidation,
}

export const getCouponsFromCMSSection = (section: Section) => {
  return section
    .reduce<CouponAPI[]>((acc, content) => {
      const coupons =
        content.content_type_id === 'product_shelf' ? content.data.coupons : []
      return coupons?.length ? [...acc, ...coupons] : acc
    }, [])
    .map(normalizeCoupon)
}

export const loadBeacons = (section: Section) => {
  section.forEach((data) => {
    if (
      data.content_type_id === 'product_shelf' &&
      data.metadata?.read_operation_info?.aggregate_onLoadBeacons
    ) {
      criteoLoadBeaconForProducts(
        data.metadata.read_operation_info.aggregate_onLoadBeacons
      )
    } else if (
      data.content_type_id === 'ad_banner' &&
      data.metadata.read_operation_info.load_beacon
    ) {
      criteoApi(data.metadata.read_operation_info.load_beacon)
    }
  })
}

function shouldLogMissingComponent(component: unknown) {
  return (
    !component && // only log if no component is found
    isProduction && // only care about production
    !isOnServer() && // we will only log for code running on the client
    !window.location.pathname.includes(routes.CMS_PREVIEW_PAGE.url) // missing components in Preview is expected
  )
}

export function getComponentForContentTypeId(
  contentTypeId: Content['content_type_id']
) {
  const Component = cmsSectionComponents[contentTypeId]

  if (shouldLogMissingComponent(Component)) {
    logError('CMS: Component not found for content_type_id', { contentTypeId })
  }

  return Component
}

export function getLayoutForContentTypeId(
  contentTypeId: CMSPageData['content']['content_type_id']
) {
  const Component = layoutComponents[contentTypeId]

  if (shouldLogMissingComponent(Component)) {
    logError('CMS: Layout not found for content_type_id', { contentTypeId })
  }

  return Component
}
