import { useEffect, useMemo } from 'react'
import {
  type CmsMappedComponents,
  type Content,
  type Section,
} from '@/components/CMS/types'
import dynamic from 'next/dynamic'
import { CouponsContextProvider } from '@/components/Coupons/CouponsContext'
import {
  getComponentForContentTypeId,
  getCouponsFromCMSSection,
  loadBeacons,
} from '@/components/CMS/utils'
import { CMSContextProvider } from '@/components/CMS/CMSContext'
import { DeferredShelf } from '@/components/CMS/DeferredShelf'

// We use plopjs to generate templated code for new CMS components.
// You can run it with `yarn plop` or see the implementation in plopfile.js
/* PLOP_INJECT_DYNAMIC_IMPORT */

const StoresCarousel = dynamic(
  async () =>
    (await import('@/components/CMS/components/StoresCarousel')).StoresCarousel
)

const GiftCardPurchaseForm = dynamic(
  async () =>
    (await import('@/components/CMS/components/GiftCardPurchaseForm'))
      .GiftCardPurchaseForm
)

const LayoutGroup = dynamic(
  async () =>
    (await import('@/components/CMS/components/LayoutGroup')).LayoutGroup
)

const DynamicPastOrdersShelf = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/PastOrdersShelf'))
      .PastOrdersShelf
)

const DynamicHeader = dynamic(
  async () => (await import('@/components/Headers/CMSHeader')).CMSHeader
)

const DynamicSpecializedHeader = dynamic(
  async () =>
    (await import('@/components/Headers/CMSHeader/SpecializedHeader'))
      .SpecializedHeader
)

const DynamicSplitHero = dynamic(
  async () => (await import('@/components/CMS/components/SplitHero')).SplitHero
)
const DynamicSplitHeroV2 = dynamic(
  async () =>
    (await import('@/components/CMS/components/SplitHeroV2')).SplitHeroV2
)

const DynamicHeadlineContainer = dynamic(
  async () =>
    (await import('@/components/CMS/components/HeadlineContainer'))
      .HeadlineContainer
)

const DynamicFooter = dynamic(
  async () => (await import('@/components/Footers/CMSFooter')).CMSFooter
)

const DynamicAnnouncementBannerV2 = dynamic(
  async () =>
    (await import('@/components/CMS/components/AnnouncementBannerV2'))
      .AnnouncementBannerContainer
)

const DynamicRetailerHero = dynamic(
  async () =>
    (await import('@/components/CMS/components/RetailerHero')).RetailerHero
)

const DynamicFullWidthHero = dynamic(
  async () =>
    (await import('@/components/CMS/components/FullWidthHero')).FullWidthHero
)

const DynamicFullWidthSplitSection = dynamic(
  async () =>
    (await import('@/components/CMS/components/FullWidthSplitSection'))
      .FullWidthSplitSection
)

const DynamicSplitInfoAccordion = dynamic(
  async () =>
    (await import('@/components/CMS/components/SplitInfoAccordion'))
      .SplitInfoAccordion
)

const DynamicDemoSlider = dynamic(
  async () =>
    (await import('@/components/CMS/components/DemoSlider')).DemoSlider
)

const DynamicPromoBanner = dynamic(
  async () =>
    (await import('@/components/CMS/components/PromoBanner')).PromoBanner
)

const DynamicSecondaryStorytelling = dynamic(
  async () =>
    (await import('@/components/CMS/components/SecondaryStorytelling'))
      .SecondaryStorytelling
)

const DynamicCTABanner = dynamic(
  async () => (await import('@/components/CMS/components/CTABanner')).CTABanner
)

const DynamicAccordion = dynamic(
  async () =>
    (await import('@/components/CMS/components/Accordion')).AccordionLayout
)

const DynamicMultiStory = dynamic(
  async () =>
    (await import('@/components/CMS/components/MultiStory')).MultiStory
)

const DynamicPlaceholder = dynamic(
  async () =>
    (await import('@/components/CMS/components/Placeholder')).Placeholder
)

const DynamicRichTextRenderer = dynamic(
  async () =>
    (await import('@/components/CMS/RichTextRenderer')).RichTextRenderer
)

const MembershipPricing = dynamic(
  async () =>
    (await import('@/components/CMS/components/MembershipPricing'))
      .MembershipPricing
)

const TestimonialSection = dynamic(
  async () =>
    (await import('@/components/CMS/components/Testimonials'))
      .TestimonialSection
)

const Carousel = dynamic(
  async () => (await import('@/components/CMS/components/Carousel')).Carousel
)

const RetailerLogos = dynamic(
  async () =>
    (await import('@/components/CMS/components/RetailerLogos')).RetailerLogos
)

const StandardNav = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/StandardNav'))
      .StandardNav
)

const EnhancedNav = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/EnhancedNav'))
      .EnhancedNav
)

const SmallNav = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/SmallNav')).SmallNav
)

const OverlapHero = dynamic(
  async () =>
    (await import('@/components/CMS/components/OverlappingHero'))
      .OverlappingHero
)

const CMSProductShelf = dynamic(
  async () =>
    (
      await import(
        '@/components/CMS/components/Shelves/ProductShelf/CMSProductShelf'
      )
    ).CMSProductShelf
)

const FullWidthBanner = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/FullWidthBanner'))
      .FullWidthBanner
)

const EnhancedProduct = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/EnhancedProduct'))
      .EnhancedProduct
)

const LargePromoCarousel = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/LargePromoCarousel'))
      .LargePromoCarousel
)

const DynamicShiptAiShelf = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/ShiptAi')).ShiptAi
)

const DynamicAdBanner = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/CMSAdBanner')).AdBanner
)

const DynamicCampaignPromoBanner = dynamic(
  async () =>
    (
      await import(
        '@/components/CMS/components/Shelves/CMSCampaignPromoBanner/CMSCampaignPromoBanner'
      )
    ).CMSCampaignPromoBanner
)

const DynamicPartnershipBanner = dynamic(
  async () =>
    (await import('@/components/CMS/components/PartnershipBanner'))
      .PartnershipBanner
)

const DynamicMultiRetailerProducts = dynamic(
  async () =>
    (
      await import(
        '@/components/CMS/components/Shelves/MultiRetailerProductShelves'
      )
    ).MultiRetailerProductShelves
)

const HeroCardCarousel = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/HeroCardCarousel'))
      .HeroCardCarousel
)
const DynamicSmallPromoCarousel = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/SmallPromoCarousel'))
      .SmallPromoCarousel
)

const CMSCouponShelf = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/CMSCouponShelf'))
      .CMSCouponShelf
)
const DynamicZipCheckForm = dynamic(
  async () => (await import('@/components/Marketing/ZipCheckForm')).ZipCheckForm
)

// We use plopjs to generate templated code for new CMS components.
// You can run it with `yarn plop` or see the implementation in plopfile.js
export const cmsSectionComponents: CmsMappedComponents<
  Content['content_type_id']
> = {
  /* PLOP_INJECT_CMS_SECTION_COMPONENT */
  stores_carousel: StoresCarousel,
  gift_card_form: GiftCardPurchaseForm,
  past_orders_shelf: DynamicPastOrdersShelf,
  announcement_banner_container: DynamicAnnouncementBannerV2,
  header: DynamicHeader,
  specialized_header: DynamicSpecializedHeader,
  split_hero: DynamicSplitHero,
  split_hero_v2: DynamicSplitHeroV2,
  headline_container: DynamicHeadlineContainer,
  footer: DynamicFooter,
  retailer_hero: DynamicRetailerHero,
  full_width_hero: DynamicFullWidthHero,
  full_width_split_section: DynamicFullWidthSplitSection,
  split_info_accordion_container: DynamicSplitInfoAccordion,
  demo_slider_container: DynamicDemoSlider,
  promo_banner: DynamicPromoBanner,
  secondary_storytelling: DynamicSecondaryStorytelling,
  cta_banner: DynamicCTABanner,
  accordion: DynamicAccordion,
  multistory: DynamicMultiStory,
  segway_placeholder: DynamicPlaceholder,
  legal: DynamicRichTextRenderer,
  membership_pricing: MembershipPricing,
  testimonial: TestimonialSection,
  carousel: Carousel,
  standard_nav: StandardNav,
  enhanced_nav: EnhancedNav,
  product_shelf: CMSProductShelf,
  small_nav: SmallNav,
  overlap_hero: OverlapHero,
  retailer_logos: RetailerLogos,
  full_width_banner: FullWidthBanner,
  enhanced_product: EnhancedProduct,
  large_promo_carousel: LargePromoCarousel,
  ad_banner: DynamicAdBanner,
  campaign_promo_banner: DynamicCampaignPromoBanner,
  partnership_banner: DynamicPartnershipBanner,
  multi_retailer_product_shelves: DynamicMultiRetailerProducts,
  urchin_shiptgpt_v2: DynamicShiptAiShelf,
  hero_card_carousel: HeroCardCarousel,
  small_promo_carousel: DynamicSmallPromoCarousel,
  coupon_shelf: CMSCouponShelf,
  layout_group: LayoutGroup,
  zip_check: DynamicZipCheckForm,
}

export const CMSSection = ({ section }: { section: Section }) => {
  useEffect(() => {
    loadBeacons(section)
  }, [section])

  const coupons = useMemo(() => getCouponsFromCMSSection(section), [section])

  return (
    <CouponsContextProvider coupons={coupons}>
      {section.map((data, idx) => {
        const { content_type_id, id } = data

        const Component = getComponentForContentTypeId(content_type_id)
        if (!Component) return null

        return (
          <DeferredShelf
            data={data}
            key={`${id}-${idx}`}
            contentTypeId={content_type_id}
          >
            {(resolvedData) => (
              <CMSContextProvider data={resolvedData}>
                <Component {...resolvedData} index={idx} />
              </CMSContextProvider>
            )}
          </DeferredShelf>
        )
      })}
    </CouponsContextProvider>
  )
}
