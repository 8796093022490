import {
  createContext,
  useContext,
  type ReactNode,
  useMemo,
  useCallback,
} from 'react'
import { type Content } from '@/components/CMS/types'
import { trackElementClicked, trackElementViewed } from '@/analytics/element'
import {
  type ElementClicked,
  type ElementViewed,
} from '@shipt/analytics-member-web'

type Props = { data: Content; children: ReactNode }

const CMSContext = createContext<Content | null>(null)

export const CMSContextProvider = ({ children, data }: Props) => {
  return <CMSContext.Provider value={data}>{children}</CMSContext.Provider>
}

export const useCMSContext = () => useContext(CMSContext)

const useCMSTrackingProperties = () => {
  const data = useCMSContext()
  return useMemo(
    () => ({
      display_sublocation: data?.content_type_id,
      shelf_name: data?.name,
    }),
    [data]
  )
}

export const useCMSTrackingEvents = () => {
  const cmsProperties = useCMSTrackingProperties()

  const trackCMSElementViewed = useCallback(
    (eventProperties: Omit<ElementViewed['properties'], 'location'>) => {
      trackElementViewed({ ...cmsProperties, ...eventProperties })
    },
    [cmsProperties]
  )

  const trackCMSElementClicked = useCallback(
    (eventProperties: Omit<ElementClicked['properties'], 'location'>) => {
      trackElementClicked(
        { ...cmsProperties, ...eventProperties },
        { sendImmediately: true }
      )
    },
    [cmsProperties]
  )

  return {
    trackCMSElementViewed,
    trackCMSElementClicked,
  }
}
