import { type ComponentType } from 'react'
import { type CMSPageData } from '@/components/CMS/types'
import { CMSLayoutContextProvider } from '@/components/CMS/CMSLayoutContext'
import { GlobalHomePageLayout } from '@/components/CMS/components/Layouts/GlobalHomePageLayout'
import { RetailerHomePageLayout } from '@/components/CMS/components/Layouts/RetailerHomePageLayout'
import { PageLayout } from '@/components/CMS/components/Layouts/PageLayout'
import { MarketplacePageLayout } from '@/components/CMS/components/Layouts/MarketplacePageLayout'
import { getLayoutForContentTypeId } from '@/components/CMS/utils'

export const layoutComponents: Record<
  CMSPageData['content']['content_type_id'],
  // Using "any" type to allow dynamic component rendering with various prop/CMS field types
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  Maybe<ComponentType<any>>
> = {
  page_layout: PageLayout,
  global_home_page_layout: GlobalHomePageLayout,
  retailer_home_page_layout: RetailerHomePageLayout,
  marketplace_page_layout: MarketplacePageLayout,
}

export const LayoutRenderer = ({
  layoutData,
}: {
  layoutData: CMSPageData['content']
}) => {
  const contentTypeId = layoutData.content_type_id

  const Component = getLayoutForContentTypeId(contentTypeId)
  if (!Component) return null

  return (
    <CMSLayoutContextProvider data={contentTypeId}>
      <Component layoutData={layoutData} />
    </CMSLayoutContextProvider>
  )
}
