import Script from 'next/script'
import { scripts } from '@/lib/ThirdPartyScripts/scripts'

export function SprinklrChat() {
  const nonce = process.env.NEXT_PUBLIC_NONCE_PLACEHOLDER

  return (
    <Script
      id="sprinklr"
      nonce={nonce}
      dangerouslySetInnerHTML={{
        __html: scripts.sprinklr,
      }}
    />
  )
}
